import { graphql, useStaticQuery } from 'gatsby'
import { ImageDataLike } from 'gatsby-plugin-image'

type ReturnTypes = {
  images: {
    edges: [
      {
        node: {
          relativePath: string
          name: string
          childImageSharp: ImageDataLike
          relativeDirectory: string
        }
      }
    ]
  }
}

export const useGetImageList = () => {
  const data = useStaticQuery<ReturnTypes>(graphql`
    query {
      images: allFile {
        edges {
          node {
            relativePath
            name
            childImageSharp {
              gatsbyImageData(width: 800)
            }
            relativeDirectory
          }
        }
      }
    }
  `)
  return data.images
}
