import React from 'react'
import { useGetImageList } from '../../../hooks/useGetImageList'
import { ImageListPhotoCard } from '../../ui-parts/ImageListPhotoCard/ImageListPhotoCard'
import { getImage } from 'gatsby-plugin-image'
import Header from '../../ui-parts/Header/Header'
import * as styles from './ImageList.module.scss'
import { DarkModeButton } from '../../ui-elements/DarkModeButton/DarkModeButton'

const ImageList: React.FC = () => {
  const data = useGetImageList()
  const imageList = data.edges.filter((n) => {
    if (n.node.relativeDirectory === 'portfolio') {
      return true
    }
  })
  return (
    <React.Fragment>
      <Header></Header>
      <main>
        {/* <DarkModeButton /> */}
        <div className={styles.container}>
          <div className={styles.container__imagelist}>
            {imageList.map((item) => (
              <ImageListPhotoCard
                imageFile={getImage(item.node.childImageSharp)!}
                key={item.node.name}
              />
            ))}
          </div>
        </div>
      </main>
    </React.Fragment>
  )
}

export default ImageList
