import React from 'react'
import { HamburgerButton } from '../../ui-elements/HamburgerButton/HamburgerButton'
import * as styles from './Header.module.scss'

const Header = () => {
  return (
    <header className={styles.header}>
      <h1 className={styles.header__name}>Daichi Yamaoka</h1>
      <HamburgerButton />
    </header>
  )
}

export default Header
