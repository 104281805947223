import React, { useState } from 'react'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'
import * as styles from './ImageListPhotoCard.module.scss'
import { ImageModal } from '../ImageModal/ImageModal'
import gsap from 'gsap'

type Props = {
  imageFile: IGatsbyImageData
}

export const ImageListPhotoCard: React.FC<Props> = (props) => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const onEnter = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1.04 })
  }
  const onLeave = ({ currentTarget }) => {
    gsap.to(currentTarget, { scale: 1 })
  }
  return (
    <React.Fragment>
      <div
        className={styles.container}
        onClick={() => setIsOpenModal(!isOpenModal)}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
      >
        <GatsbyImage
          image={getImage(props.imageFile)!}
          alt="first"
          className={styles.container__image}
        />
      </div>
      {isOpenModal ? (
        <ImageModal
          imageFile={props.imageFile}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
        />
      ) : null}
    </React.Fragment>
  )
}
