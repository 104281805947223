import * as React from 'react'
import Layout from '../components/layout'
import ImageList from '../components/layouts/ImageList/ImageList'

const Portfolio = () => {
  return (
    <Layout pageTitle="Portfolio">
      <ImageList />
    </Layout>
  )
}

export default Portfolio
