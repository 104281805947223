import React, { useRef, useEffect } from 'react'
import { GatsbyImage, IGatsbyImageData, getImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import * as styles from './ImageModal.module.scss'

type Props = {
  imageFile: IGatsbyImageData
  isOpenModal: boolean
  setIsOpenModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ImageModal: React.FC<Props> = (props) => {
  const modalOverlayRef = useRef(null)

  // 初回のレンダリング時にoverlayを表示
  useEffect(() => {
    if (props.isOpenModal) {
      showOverlay()
    }
  }, [])

  const showOverlay = () => {
    gsap.to(modalOverlayRef.current, { duration: 0.5, opacity: 0.85 })
  }
  const hideOverlay = () => {
    gsap.to(modalOverlayRef.current, { duration: 0.5, autoAlpha: 0 })
  }

  const clickOvelay = (isOpen: boolean) => {
    if (isOpen) {
      hideOverlay()
      props.setIsOpenModal(false)
    } else {
      showOverlay()
      props.setIsOpenModal(true)
    }
  }
  var imageStyle
  if (props.imageFile.height > props.imageFile.width) {
    imageStyle = styles.modalImage__portrait
  } else {
    imageStyle = styles.modalImage__landscape
  }
  return (
    <React.Fragment>
      <div className={styles.modalOverlay} ref={modalOverlayRef}></div>
      <div
        className={styles.modal}
        onClick={() => clickOvelay(props.isOpenModal)}
      >
        <div className={styles.modalDialog}>
          <GatsbyImage
            image={getImage(props.imageFile)!}
            alt="modal-image"
            className={imageStyle}
          />
        </div>
      </div>
    </React.Fragment>
  )
}
